import React, { Fragment} from 'react';
import {Link} from "gatsby";
import { compose } from 'recompose';
import Layout from '../components/layout';
import { css } from "@emotion/core";
import * as ROUTES from '../constants/routes';
import ReactDOM from "react-dom";
import Helmet from "react-helmet";
import Icon from '@material-ui/core/Icon';
import Navlinks from '../components/nav';
import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification,
} from '../components/Session';

import Portfolios from '../components/Portfolios';

function intentCount(){
    console.log(document.querySelectorAll('.portfolioItem').length)
}

function componentDidMount() {
  const numOfAllPorts = document.querySelectorAll('.portfolioItem').length;
  if (numOfAllPorts) {
    numOfAllPorts.innerHTML = this.props.prop[1]
  }
}

var numOfPortfolios = 0;

function revealArchived() {
    var pList = document.getElementById("portfolioListContainer");
    var pHeader = document.getElementById("portfolioHeader");
    if (pList.classList.contains('activeP')) {
      pList.classList.remove('activeP');
      pList.classList.add('archivedP');
      pHeader.classList.remove('pActive');
      pHeader.classList.add('pArchived');
    } else {
      pList.classList.add('activeP');
      pList.classList.remove('archivedP');
      pHeader.classList.add('pActive');
      pHeader.classList.remove('pArchived');
    }
  }

const condition = authUser => !!authUser;
function portfolioFormOpen() {
    var pForm = document.getElementById("portfolioForm");
    pForm.classList.add('active');
  }
const PortfolioHeading = () => (
    <Fragment>
      <AuthUserContext.Consumer>
        {authUser => (
          <React.Fragment>
            <Helmet>
              <body className="portfolio" />
              <title>My Portfolios | SaltSmart</title>
            </Helmet>
            <header className="mainHead flexContainer faCenter fjCenter portfolioHead">      
                <div className="fCol12 menuCtrl">
                    <Link className="headerTitle" to={ROUTES.HOME}>
                        <span className="arrowbk"><Icon>arrow_back</Icon></span> Home
                    </Link>            
                    {/* Nav Side */}          
                    <Navlinks />
                </div>
            </header>
            <div id="tabsContainer" className="tabsContainer portfolio" css={css`margin-top:5vh;`}>
              <Portfolios />
            </div>
          </React.Fragment>
        )}
      </AuthUserContext.Consumer>
    </Fragment>
);
const PortfolioHeader = compose(
  withEmailVerification,
  withAuthorization(condition),
)(PortfolioHeading);


export default  () => (
  <Layout>
    <PortfolioHeader />
  </Layout>
);