import React, { Component } from 'react';
import PortfoliosItem from './PortfoliosItem';
import PortfoliosItemArchive from './PortfoliosItemArchive';
import {sortableContainer, sortableElement, sortableHandle} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import Collections from './Portfolios';
const items =[];
const archivedItems =[];
const unarchivedItems =[];
const DragHandle = sortableHandle(() => <div className="drag hide" draggable>
<span className="dragIcon"></span>
</div>);
const SortableItem = sortableElement(({value, user,
  edit,
  archive,
  remove,
  unarchive,
  key,
  newKey,
  collections,
pIndex,
userId,
index,
newIndex,
itemsChange}) => 
  <li id={newKey} key={`${newKey}-itemunp`} className={"portfolioItem flexContainer activeP activePI p"+newIndex}>
    <PortfoliosItem
      authUser={user}
      userId={userId}
      key={newKey+newIndex}
      collection={value}
      onEditCollection={edit}
      onChangeArchiveTrue={archive}
      onChangeArchiveFalse={unarchive}
      onRemoveCollection={remove}
      collections={collections}
      indexId={newIndex}
      itemsChange={itemsChange}
    />
    <DragHandle/>
</li>);
const SortableItemArchived = sortableElement(({value, user,
  edit,
  archive,
  remove,
  unarchive,
  key,
  collections,
pIndex,
newKey}) => 
  <li key={`${newKey}-itemar`} className="portfolioItem flexContainer archivedP archivedPI">
    <PortfoliosItemArchive
      authUser={user}
      key={`itemar-${newKey}`}
      collection={value}
      onEditCollection={edit}
      onRemoveCollection={remove}
      onChangeArchiveFalse={unarchive}
      collections={collections}
    />
    <DragHandle />
</li>);

//const SortableItem = SortableElement(({authUser,collection,ckey,onEditCollection,onChangeArchiveTrue,onRemoveCollection}) => <PortfoliosItem authUser={authUser}  key={ckey} collection={collection} onEditCollection={onEditCollection} onChangeArchiveTrue={onChangeArchiveTrue} onRemoveCollection={onRemoveCollection}/>);

const SortableContainer = sortableContainer(({children}) => {
  return <ul key='unarchived' id="portfolioListActive" className="fCol12 portfolioList activeP">{children}</ul>;
});

const SortableContainerArchived = sortableContainer(({children}) => {
  return <ul id="portfolioListArchived" className="fCol12 portfolioList archivedP">{children}</ul>;
});





{/*class SortableComponent extends Component {
  state = {
    items: items,
  };
  onSortEnd = ({oldIndex, newIndex}) => {
    this.setState(({items}) => ({
      items: arrayMove(items, oldIndex, newIndex),
    }));
  };
  render() {
    return <SortableList items={this.state.items} onSortEnd={this.onSortEnd} />;
  }
}*/}

class SortableComponent extends Component {
  
  state = {
    items: this.props.items,
    itemsChanged: 0
  };
  /*onChangeOrderTrue = (uid, newIndex) => {
    this.onChangeOrder(uid, newIndex);
  };*/
  

  onSetIndex= (item, newindex) => {
    this.props.onChangeOrder(item, newindex);
    this.setState({
      indexId: newindex
    })
  };
  onSortEnd = ({oldIndex, newIndex}) => {
    //console.log(this.props.items[oldIndex].value.uid)
    this.setState(({items, itemsChange}) => ({
      items: arrayMove(items, oldIndex, newIndex),
      itemsChange: itemsChange+1,
    }));
    this.props.onChangeOrder(this.state.items);
    this.setState(() => ({
      collections: this.props.collections
    }));
  };
  render() {
    const { items } = this.state;
    //const { items } = this.props;
    if(this.props.items == unarchivedItems){
      return (
        <SortableContainer onSortEnd={this.onSortEnd} onChangeOrder={this.onChangeOrder} onChangeArchiveTrue={this.onChangeArchiveTrue} useDragHandle>
          {items.map((item, index) => (
            <SortableItem key={`aitem-${item.value.uid}`} onChangeArchiveTrue={this.onChangeArchiveTrue} itemsChange={this.state.itemsChange} newKey={item.value.uid} index={index} newIndex={index} value={item.value} user={item.user} userId={item.value.userId} archive={item.archive} unarchive={item.unarchive} edit={item.edit} remove={item.remove} pIndex={item.pIndex} collections={item.collections}/>
          ))}
        </SortableContainer>
      );
    } else {
      return (
        <SortableContainerArchived onSortEnd={this.onSortEnd} onChangeOrder={this.onChangeOrder} useDragHandle>
          {items.map((item, index) => (
            <SortableItemArchived key={`aitem-${item.pIndex}`} index={index} value={item.value} user={item.user} archive={item.archive} unarchive={item.unarchive} newKey={item.value.uid} edit={item.edit} remove={item.remove} pIndex={item.pIndex} collections={item.collections}/>
          ))}
        </SortableContainerArchived>
      );
    }

  }
}



const MessageList = ({
  authUser,
  collections,
  onEditCollection,
  onChangeArchiveTrue,
  onRemoveCollection,
  onChangeArchiveFalse,
  onChangeOrder,
  onChangeOrderOld
}) => (
  <div>

      {/*collections.map(collection => (
        <PortfoliosItem
          authUser={authUser}
          key={collection.uid}
          collection={collection}
          onEditCollection={onEditCollection}
          onChangeArchiveTrue={onChangeArchiveTrue}
          onRemoveCollection={onRemoveCollection}
        />
      ))*/}
      <div className="hide">{items.length=0}</div>
      <div className="hide">{unarchivedItems.length=0}</div>
      <div className="hide">{archivedItems.length=0}</div>
      {
      collections.sort((a, b) => a.indexId - b.indexId).map((collection, iC) => (
        <React.Fragment>
          {/*console.log(collection)*/}
          {authUser.uid === collection.userId && collection.archived === true && 
            <div className="hide">{archivedItems.push({'user':authUser, 'value':collection, 'remove':onRemoveCollection, 'unarchive':onChangeArchiveFalse, 'archive':onChangeArchiveTrue, 'edit':onEditCollection, 'pIndex': iC, 'reorder':onChangeOrder, 'collections':collections})}</div>
          }
          {authUser.uid === collection.userId && collection.archived === false && 
            <div className="hide">{unarchivedItems.push({'user':authUser, 'value':collection, 'remove':onRemoveCollection, 'archive':onChangeArchiveTrue, 'unarchive':onChangeArchiveFalse, 'edit':onEditCollection, 'pIndex': iC, 'reorder':onChangeOrder, 'collections':collections})}</div>
          }
          
        </React.Fragment>
      ))
      
      
      /*collections.map((collection, iC) => (
        <React.Fragment>
          
          {console.log(collection)}
          {authUser.uid === collection.userId && collection.archived === true && 
            <div className="hide">{archivedItems.push({'user':authUser, 'value':collection, 'remove':onRemoveCollection, 'unarchive':onChangeArchiveFalse, 'edit':onEditCollection, 'pIndex': iC, 'reorder':onChangeOrder, 'reorderold':onChangeOrderOld})}</div>
          }
          {authUser.uid === collection.userId && collection.archived === false && 
            <div className="hide">{unarchivedItems.push({'user':authUser, 'value':collection, 'remove':onRemoveCollection, 'archive':onChangeArchiveTrue, 'edit':onEditCollection, 'pIndex': iC})}</div>
          }
          
        </React.Fragment>
      ))*/
      
      
      
      }
      {/*console.log(unarchivedItems)*/}
      
      
      {<SortableComponent onChangeOrder={onChangeOrder} onChangeArchiveTrue={onChangeArchiveTrue} items={unarchivedItems}/>}
      {<SortableComponent onChangeOrder={onChangeOrder} items={archivedItems}/>}
      
      
      
      

    
  </div>
);

export default MessageList;